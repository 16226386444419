import React from 'react';
import PropTypes from 'prop-types';

const CallToActionContact = ({ primary = `blue-400` }) => {
  return (
    <div className="relative">
      <div className="sm:flex justify-end mt-24 relative up-50">
        <div className="absolute bg-gray-800 inset-0 my-4 w-full" />
        <p
          className={`cta-right bg-${primary} p-8 font-bold font-display leading-tight text-white text-xl md:text-2xl lg:text-3xl text-right uppercase`}
        >
          <span className="inline-block ml-8 sm:ml-24 whitespace-no-wrap">
            Servicing
          </span>
          <br />
          <span className="inline-block ml-8 sm:ml-24 whitespace-no-wrap">
            {' '}
            the Mid North
          </span>
          <br />
          <span className="inline-block ml-8 sm:ml-24 whitespace-no-wrap">
            {' '}
            Coast of NSW
          </span>
        </p>
      </div>
    </div>
  );
};

CallToActionContact.propTypes = {
  primary: PropTypes.string,
};

export default CallToActionContact;
