import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const OurTeam = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "team.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div id="our-team" className="max-w-6xl mx-auto px-4 py-12 w-full">
      <div className="flex flex-wrap -mx-4 pb-12 md:pb-0">
        <div className="mb-4 md:pb-12 px-8">
          <div className="float-right mb-4 md:m-4 w-full md:w-1/2">
            <Image
              style={{ height: `100%` }}
              fluid={data.file.childImageSharp.fluid}
            />
          </div>
          <h2 className="font-bold font-display leading-none mb-4 md:mt-4 text-4xl text-blue-600 uppercase">
            About Us
          </h2>
          <p>
            As one of the areas most trusted tradesmen, Custom Metal Roofing has
            successfully serviced numerous homes across Sydney and the South
            Coast of New South Wales.
          </p>
          <p className="mt-4">
            Owner-operator, Mark Dunkley, is a qualified roof plumber with more
            than twenty years of experience in the industry. With the assistance
            of his wife, Claire, Mark began operating as Custom Metal Roofing
            and has since supported the development of numerous local homes. He
            is considered to be a local expert in roofing and building projects.
          </p>
          <p className="mt-4">
            The success of the business is a direct result of Mark’s client
            satisfaction focus – he is not happy until you are. With initial and
            ongoing consultation, Mark ensures that he has a thorough
            understanding of your needs and expectations concerning your roof.
            Your desires, combined with Mark’s knowledge regarding practical and
            effective roofing, will ensure that your property is safe, appealing
            and functional.
          </p>
          <p className="mt-4">
            Although building new roofs is his specialty trade, Mark also
            provides a variety of other services and products to ensure the
            comprehensive completion of your building exterior. In his years of
            experience, Mark has installed and fixed wall cladding, gutters,
            gutter guards, fascia, downpipes and skylights. All of Mark’s
            materials and tools are sourced from reliable brands such as
            BlueScope Colorbond Steel so that your new home features will be
            long-lasting.
          </p>
          <p className="mt-4">
            Custom Metal Roofing’s extensive portfolio includes family
            residential homes, multi-storey buildings, apartments, townhouses,
            warehouses and commercial properties. No challenge is too large or
            too small. Depending on your unique budget and financial
            circumstances, Custom Metal Roofing will find the right solution to
            suit your needs.
          </p>
          <p className="mt-4">
            Being a family-owned and operated business, Mark and Claire
            wholeheartedly appreciate the importance of a quality, reliable home
            for you and your family. All projects are conducted with the utmost
            care and attention. Your home building or renovation plans will be
            perfectly supplemented with specialised roofing assistance from
            Mark.
          </p>
          <p className="mt-4">
            Custom Metal Roofing will take the necessary time to design and
            install property features that are ideal to your needs. Working with
            a qualified and meticulous professional like Mark means that you can
            rest assured your custom made roofing solution will be of high
            quality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
