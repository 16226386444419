import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import AboutHero from '../components/AboutHero';
import CallToActionAboutHero from '../components/CallToAction/AboutHero';
import OurTeam from '../components/OurTeam';
import CallToActionContact from '../components/CallToAction/Contact';
import Contact from '../components/Contact';

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <AboutHero />
    <CallToActionAboutHero />
    <OurTeam />
    <CallToActionContact primary="blue-600" />
    <Contact primary="blue-600" secondary="blue-500" />
  </Layout>
);

export default AboutPage;
